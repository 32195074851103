@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: var(--background-0);
  transition-property: background-color, border-color, color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

body[data-theme="light"] {
  --background-0: #e5e9f0;
  --background-1: #fafafa; /* Modified from nordtheme */
  --background-2: #d8dee9;
  --text-primary: #2e3440;
  --text-secondary: ##3b4252;
}

body[data-theme="dark"] {
  --background-0: #2e3440;
  --background-1: #3b4252;
  --background-2: #434c5e;
  --text-primary: #eceff4;
  --text-secondary: #e5e9f0;
}
